import React from "react";
import abouttwo from "../../assets/About Page gifs/abouttwo.webp";

import "../../index.css";
import { OutlinedArrowButton } from "../common/outlineArrowButton";

const Aboutcont = () => {
  return (
    <>
      <div className="h-[80vh]   flex justify-center gap-x-2 md:gap-x-10  md:justify-center items-center">
        <div className="w-[75%] md:w-[40%] ml-10 md:ml-0  h-96 mt-0 md:mt-11 ">
          <h1 className="text-4xl  font-bold md:text-5xl sm:text-4xl ">
            Do you have a
          </h1>
          <h1 className="text-themeBlue text-4xl md:text-5xl sm:text-4xl font-bold">
            similar vision as ours?
          </h1>
          <p className="font-semibold text-lg  mt-4">
            We’d love you to fill in the space, & join us at Slashon
          </p>

          <OutlinedArrowButton
            buttonText="Send us an Email"
            onClick={() => {
              window.location.href =
                "mailto:hey@slashon.agency?cc=kartik@slashon.agency";
            }}
            className="mt-9"
          />
        </div>
        <div>
          <img
            alt="img"
            src={abouttwo}
            className="hidden md:block w-[30vh] phone:w-[40vh]   xl:w-[50vh]   rounded-full "
          />
        </div>
      </div>
    </>
  );
};

export default Aboutcont;
