const RouteNames = {
  HOME: "/",
  ABOUT_US: "/about-us",
  SOLUTIONS: "/solutions",
  PROJECTS: "/projects",
  PROJECT_DETAILS: (projectPath) => `/projects/${projectPath}`,
  CONTACT_US: "/contact-us",
  TNC: "/tnc",
  ERROR: "*",
};

export default RouteNames;
