import React from "react";
import { GoArrowUpRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { projectData } from "../../data/projectData";
import RouteNames from "../../RouteNames";

const ProjectSectionNext = ({ projectPath }) => {
  const navigate = useNavigate();

  // Find the index of the current project
  const currentProjectIndex = projectData.findIndex(
    (project) => project.path === projectPath
  );

  // Calculate the next project index
  const nextProjectIndex = (currentProjectIndex + 1) % projectData.length;
  const nextProject = projectData[nextProjectIndex];

  const handleNextPage = (title) => {
    navigate(RouteNames.PROJECT_DETAILS(title));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="bg-lightBlack flex flex-col-reverse xl:flex-row justify-center items-start gap-x-28 gap-y-12 relative py-16 xl:py-28 px-8 xl:px-0 w-full z-10">
      <div className="absolute h-[25%] w-[73%] top-[73%] left-[-45%] blur-[192px] rounded-full bg-themeYellow"></div>

      <div className="flex items-center pt-4">
        <h1 className="text-themeGreen text-5xl font-bold">
          Next <br />
          Project
        </h1>
        <button
          onClick={() => handleNextPage(nextProject.path)}
          className="border-themeGreen border-2 ml-5 h-16 w-16 rounded-full flex items-center justify-center"
          aria-label="Next project"
        >
          <GoArrowUpRight className="text-5xl rotate-45 text-themeGreen transition-transform duration-300 ease-in-out hover:rotate-[0deg]" />
        </button>
      </div>

      <div className="flex flex-col gap-y-3 gap-x-10">
        <div className="w-full xl:max-w-[50vh]">
          <video
            src={nextProject.video}
            muted
            autoPlay
            loop
            playsInline
            preload="auto"
            className="w-full md:w-full h-[auto] rounded-[40px] border-[0.75px] border-black object-cover"
          />
        </div>
        <div className="flex text-lg font-bold items-center gap-x-4 text-themeGreen">
          <h2>{nextProject.company}</h2>
          <span className="h-2 w-2 rounded-full bg-themeGreen" />
          <h2>{nextProject.industry}</h2>
        </div>
        <p className="text-xl text-white font-medium w-full xl:w-[50vh]">
          {nextProject.description}
        </p>
      </div>
    </div>
  );
};

export default ProjectSectionNext;
