import React from "react";
import solimga from "../../assets/Solutions Page gifs/solimga.webp";
import solimgb from "../../assets/Solutions Page gifs/solimgb.webp";
import { OutlinedArrowButton } from "../common/outlineArrowButton";
import { useNavigate } from "react-router-dom";
import RouteNames from "../../RouteNames";
const SolutionColb = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <div className="flex flex-col items-center gap-5 ">
          <h1
            className="font-bold text-neutral-800 lg:text-6xl text-4xl md:text-5xl  text-center intermediate:text-5xl 
          leading-tight"
          >
            Let’s start solving your
          </h1>
          <h1 className="font-bold lg:text-6xl sm:w-[85%] w-[80%] tracking-normal	phone-[80%]   text-4xl md:text-5xl  text-center intermediate:text-5xl  -mt-5  leading-tight text-neutral-800">
            Challenges over a{" "}
            <span className="text-[#464af8]">Cup of Chai!</span>
          </h1>

          <p className="text-md xl:text-2xl font-medium text-center w-[50%]  text-neutral-800">
            We also don’t mind Coffee & Conversations :P
          </p>
          <OutlinedArrowButton
            buttonText={"Let’s Collaborate"}
            onClick={() => {
              navigate(RouteNames.CONTACT_US);
            }}
            className={"py-[8px] px-[12px] mt-12"}
          />
        </div>
        <div className="w-full flex justify-evenly mb-12 xl:mb-0 -mt-28 intermediate:-mt-36 intermediate:gap-x-[50%] md:gap-x-[30%] sm:gap-x-[30%] gap-x-[55%]">
          <img
            src={solimga}
            alt="imga"
            className="w-[100px] h-[140px] sm:w-[200px] sm:h-[325px] md:w-[227px] md:h-[352px] phone:w-[180px] phone:h-[305px]"
            loading="lazy"
          />
          <img
            src={solimgb}
            alt="imgb"
            className="w-[100px] h-[140px] sm:w-[200px] sm:h-[325px] md:w-[227px] md:h-[352px] phone:w-[180px] phone:h-[305px]"
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
};

export default SolutionColb;
