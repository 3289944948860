import React from "react"

const TnC = () => {
  return (
    <div className="px-5 xl:px-28 py-40 xl:py-52 flex flex-col gap-16 text-left">
      <div className="flex flex-col justify-center items-start gap-5">
        <p className="font-bold text-xl md:text-4xl text-themeBlue tracking-tight mb-7 ">
          Privacy Policy
        </p>
        <p className="font-medium text-base md:text-lg text-black">
          Thank you for visiting the website of Slashon, a design and marketing
          agency dedicated to delivering creative solutions. We value your
          privacy and are committed to protecting your personal information.
          This Privacy Policy outlines how Slashon collects, uses, maintains,
          and discloses information collected from users (each, a "User") of the
          www.slashon.agency website ("Site"). This Privacy Policy applies to
          the Site and all services offered by Slashon.
        </p>
        <p className="font-bold text-lg md:text-xl text-black">
          Personal Information We Collect:
        </p>
        <p className="font-medium text-base md:text-lg text-black">
          We may collect personal identification information from Users in
          various ways, including, but not limited to, when Users visit our
          Site, fill out a form, subscribe to our newsletter, respond to a
          survey, and in connection with other activities, services, features,
          or resources we make available on our Site. Users may be asked for, as
          appropriate, name, email address, mailing address, phone number, and
          job title. Users may, however, visit our Site anonymously. We will
          collect personal identification information from Users only if they
          voluntarily submit such information to us. Users can always refuse to
          supply personally identification information, except that it may
          prevent them from engaging in certain Site-related activities.
        </p>
        <p className="font-bold text-lg md:text-xl text-black">
          Non-Personal Information We Collect:
        </p>
        <ul className="list-disc px-10 font-medium space-y-5 text-base md:text-lg text-black">
          <li>
            Cookies and Tracking Technologies: Slashon's website may use
            cookies, web beacons, and similar tracking technologies to collect
            non-personal information about Users' interactions with the site.
            These technologies help us analyze trends, administer the website,
            track Users' movements around the site, and gather demographic
            information about our user base as a whole. Users can control the
            use of cookies through their browser settings.
          </li>
          <li>
            Device Information: We may collect non-personal information about
            the devices used to access our website, such as device type,
            operating system, browser type, and screen resolution. This
            information helps us optimize the website for different devices and
            improve overall user experience.
          </li>
          <li>
            Website Analytics: Slashon may utilize third-party analytics
            services to gather non-personal information about website traffic
            and usage patterns. These services may collect data such as IP
            addresses, referring pages, and exit pages. This information is used
            to analyze website performance, identify areas for improvement, and
            measure the effectiveness of marketing campaigns.
          </li>
          <li>
            User Behavior: We may track non-personal information about User
            behavior on our website, including pages visited, links clicked, and
            actions taken. This data helps us understand how Users interact with
            our content and design, allowing us to tailor our services to better
            meet their needs.
          </li>
          <li>
            Aggregated Data: Slashon may aggregate non-personal information
            collected from Users to generate statistical reports and analyze
            trends. This aggregated data does not contain any personally
            identifiable information and is used for internal purposes only.
          </li>
        </ul>
        <p className="font-medium text-lg text-black">
          By using our website, Users consent to the collection and use of
          non-personal information as described in this Privacy Policy. We do
          not combine non-personal information with personally identifiable
          information unless required for a specific purpose and with the User's
          explicit consent.
        </p>
      </div>

      <div className="flex flex-col justify-center items-start gap-5">
        <p className="font-bold text-xl md:text-4xl text-themeBlue tracking-tight mb-7">
          Terms & Conditions
        </p>
        <p className="font-medium text-base md:text-lg text-black">
          By accessing or using the Site, you agree to be bound by these Terms &
          Conditions. If you do not agree with any part of these Terms &
          Conditions, you may not access the Site or use our services.
        </p>
        <ul className="list-disc font-medium space-y-5 text-base md:text-lg text-black ">
          <li className="font-bold mx-10 text-lg md:text-xl text-black">
            Use of Website:
          </li>
          <p>
            a. You agree to use the Site and services only for lawful purposes
            and in compliance with all applicable laws and regulations.  
          </p>
          <p>
            b. You may not use the Site or services in any manner that could
            damage, disable, overburden, or impair our servers or networks, or
            interfere with any other party's use and enjoyment of the Site.
          </p>

          <li className="font-bold mx-10 text-xl text-black">
            Intellectual Property:
          </li>
          <p>
            a. All content on the Site, including but not limited to text,
            graphics, logos, images, audio clips, and software, is the property
            of Slashon and is protected by copyright, trademark, and other
            intellectual property laws.
          </p>
          <p>
            b. You may not modify, reproduce, distribute, transmit, display,
            perform, or create derivative works from any content on the Site
            without the prior written consent of Slashon.
          </p>

          <li className="font-bold mx-10 text-xl text-black">User Content:</li>
          <p>
            a. You may submit content, such as comments, reviews, or
            testimonials, to the Site. By submitting User Content, you grant
            Slashon a non-exclusive, royalty-free, perpetual, irrevocable, and
            fully sublicensable right to use, reproduce, modify, adapt, publish,
            translate, create derivative works from, distribute, and display
            such User Content throughout the world in any media.
          </p>
          <p>
            b. You represent and warrant that you own or have the necessary
            rights, licenses, consents, and permissions to submit User Content
            and grant the above license to Slashon.
          </p>

          <li className="font-bold mx-10 text-xl text-black">
            Limitation of Liability:
          </li>
          <p>
            a. In no event shall Slashon or its directors, officers, employees,
            or agents be liable for any indirect, incidental, special,
            consequential, or punitive damages, including without limitation,
            loss of profits, data, use, goodwill, or other intangible losses,
            arising out of or in connection with your use of the Site or
            services.
          </p>

          <li className="font-bold mx-10 text-xl text-black">
            Changes to Terms & Conditions:
          </li>
          <p>
            a. Slashon reserves the right to modify or replace these Terms &
            Conditions at any time. Any changes will be effective immediately
            upon posting on the Site. Your continued use of the Site and
            services after any such changes constitutes your acceptance of the
            new Terms & Conditions
          </p>
        </ul>

        <p className="font-medium text-base md:text-lg text-black">
          If you have any questions about these Terms & Conditions, please
          contact us at hey@slashon.agency
        </p>
      </div>
    </div>
  )
}

export default TnC
