import React from "react";
import { useNavigate } from "react-router-dom";
import { OutlinedArrowButton } from "../common/outlineArrowButton";
import RouteNames from "../../RouteNames";
const WeAreSlashon = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col xl:flex-row justify-between px-5 xl:px-10 py-32 text-lightBlack container mx-auto">
      <h2 className="font-bold text-[35px] xl:text-[54px] ">
        We are <span className="text-themeBlue">Slashons</span>
      </h2>
      <div className="space-y-8 mt-4 xl:mt-0">
        <p className="font-medium xl:text-xl max-w-[619px] ">
          A team of enthusiasts aiming to excel in balancing creative design and
          strategic thinking for finding a perfect blend that elevates your
          brand story.
        </p>
        <OutlinedArrowButton
          buttonText="About Us"
          onClick={() => navigate(RouteNames.ABOUT_US)}
        />
      </div>
    </div>
  );
};

export default WeAreSlashon;
