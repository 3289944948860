import { useParams } from "react-router-dom"
import PrejectSectionHero from "../components/ProjectSection/PrejectSectionHero"
import ProjectSectionImage from "../components/ProjectSection/ProjectSectionImage"
import ProjectSectionDetail from "../components/ProjectSection/ProjectSectionDetail"
import ProjectSectionNext from "../components/ProjectSection/ProjectSectionNext"
import { projectData } from "../data/projectData"
import MetaDecorator from "../components/MetaDecorator"

const ProjectDetails = () => {
  const { projectPath } = useParams()

  const currentProject = projectData.find(
    (project) => project.path === projectPath
  )

  return (
    <div className="overflow-hidden mt-36 flex flex-col items-center m-auto">
      <MetaDecorator
        title={currentProject.company}
        desc={currentProject.description}
      />
      <PrejectSectionHero currentProject={currentProject} />
      <ProjectSectionImage currentProject={currentProject} />
      <ProjectSectionDetail />
      <ProjectSectionNext projectPath={projectPath} />
    </div>
  )
}

export default ProjectDetails
