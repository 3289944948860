import React, { useEffect, useState, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ham from "../assets/hamburger.svg"
import hamWhite from "../assets/hamburger-white.svg"
import close from "../assets/close.svg"
import closeWhite from "../assets/close-white.svg"
import { OutlinedArrowButton, ButtonVariant } from "./common/outlineArrowButton"
import newWhite from "../assets/Slashon Logos/slashon.agency_logo_white.webp"
import newBlack from "../assets/Slashon Logos/slashon.agency_logo.webp"
import RouteNames from "../RouteNames"

const Navbar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [active, setActive] = useState()
  const [navOpen, setNavOpen] = useState(false)
  const menuRef = useRef(null)

  const checkRoute =
    location.pathname === RouteNames.ABOUT_US ||
    location.pathname === RouteNames.SOLUTIONS ||
    location.pathname === RouteNames.PROJECTS

  const buttons = [
    { name: "About", url: RouteNames.ABOUT_US },
    { name: "Solutions", url: RouteNames.SOLUTIONS },
    { name: "Projects", url: RouteNames.PROJECTS },
  ]

  useEffect(() => {
    setActive(location.pathname)
  }, [location])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setNavOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div
      key="Navbar"
      ref={menuRef}
      style={{
        background: navOpen && "#9c939352",
        backdropFilter: navOpen && "blur(24px)",
        WebkitBackdropFilter: navOpen && "blur(24px)",
        boxShadow: navOpen && "0px 2px 24px 0px rgba(0, 0, 0, 0.12)",
      }}
      className="z-[111] transition-all duration-300 ease-linear bg-transparent container mx-auto absolute top-0 left-0 right-0 p-3 lg:mt-9"
    >
      <div className="flex justify-between items-center">
        <div className="flex justify-center w-[130px] md:w-[143px]">
          <img
            src={checkRoute ? newWhite : newBlack}
            className="h-full w-full scale-90 lg:scale-100"
            alt=""
            loading="lazy"
            onClick={() => navigate(RouteNames.HOME)}
          />
        </div>
        <img
          src={
            navOpen
              ? checkRoute
                ? closeWhite
                : close
              : checkRoute
              ? hamWhite
              : ham
          }
          onClick={() => setNavOpen(!navOpen)}
          className="p-1 lg:hidden"
          alt=""
          loading="lazy"
        />
        <div
          className={`hidden lg:flex justify-center items-center gap-12 py-[6px] px-[48px] rounded-full ${
            checkRoute ? "bg-[#353535] " : "bg-[#EEEEEE]"
          }`}
        >
          {buttons.map((e, index) => (
            <button
              key={index}
              className={` text-center text-lg font-bold px-3 ${
                checkRoute ? "hover:text-themeGreen" : "hover:text-themeBlue"
              } ${active === e?.url ? "text-white" : "text-[#717171]"}`}
              onClick={() => navigate(e?.url)}
            >
              {e?.name}
            </button>
          ))}
        </div>
        {location.pathname !== RouteNames.CONTACT_US ? (
          <OutlinedArrowButton
            buttonText={"Contact Us"}
            variant={checkRoute ? ButtonVariant.GREEN : ButtonVariant.BLUE}
            onClick={() => navigate(RouteNames.CONTACT_US)}
            className="hidden lg:flex"
          />
        ) : (
          <div className="hidden xl:block"></div>
        )}
      </div>
      <div
        className={`lg:hidden flex flex-col items-start gap-5 mt-5 overflow-hidden transition-all duration-300 ease-linear ${
          navOpen ? "max-h-96 px-5 pb-10" : "max-h-0"
        }`}
      >
        <button
          className={`font-bold w-full text-start text-xl ${
            checkRoute ? "text-white" : "text-[#262626]"
          }`}
          onClick={() => {
            navigate(RouteNames.HOME)
            setNavOpen(false)
          }}
        >
          Home
        </button>
        {buttons?.map((b, index) => (
          <button
            key={index}
            className={`font-bold w-full text-start text-xl ${
              checkRoute ? "text-white" : "text-[#262626]"
            }`}
            onClick={() => {
              navigate(b?.url)
              setNavOpen(false)
            }}
          >
            {b?.name}
          </button>
        ))}
        <button
          className={`font-bold w-full text-start text-xl ${
            checkRoute ? "text-white" : "text-[#262626]"
          }`}
          onClick={() => {
            navigate(RouteNames.CONTACT_US)
            setNavOpen(false)
          }}
        >
          Contact
        </button>
      </div>
    </div>
  )
}

export default Navbar
