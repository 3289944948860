import React from "react"
import Solutionwork from "../components/Solutionslashon/Solutionwork"
import Solutiondetail from "../components/Solutionslashon/Solutiondetail"
import SolutionColb from "../components/Solutionslashon/SolutionColb"
import Solutionhero from "../components/Solutionslashon/Solutionhero"
import MetaDecorator from "../components/MetaDecorator"

const Solutions = () => {
  return (
    <div className="relative overflow-hidden">
      <MetaDecorator
        title="Design, Branding and Growth Solutions | Slashon Agency"
        desc="Looking for a creative partner? & want to elevate your brand’s design experience with targeted results? Slashon is your ideal choice to unlock your brand’s potential."
      />
      <Solutionhero />
      <Solutionwork />
      <Solutiondetail />
      <SolutionColb />
    </div>
  )
}

export default Solutions
