import React from "react";
import downSmall from "../../assets/HomeIcons/down-white.svg";
import VerticalTextCarousel from "../common/VerticalTextCaraousal";

const ProjectHero = () => {
  return (
    <div className="bg-lightBlack h-[106vh] mb-36 relative overflow-hidden flex flex-col items-center justify-center">
      <div className="absolute h-[50%] w-[50%] bottom-[60%] right-[-30%]  blur-[192px] rounded-full bg-themebluegreen"></div>

      <div className="absolute h-[63%] w-[63%] bottom-[-25%] left-[-30%]  blur-[192px] rounded-full bg-themeYellow"></div>
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-white md:text-5xl xl:text-7xl font-bold mt-12 mb-4 xl:mb-0 text-wrap">
          We create projects that are
        </h1>
        <h2 className="h-16 xl:h-32 w-full text-center">
          <VerticalTextCarousel
            textList={["Unique", "Customised", "Versatile"]}
            fontSize="text-6xl xl:text-8xl"
            fontWeight="font-black"
            justifyContent="justify-center"
            textAlign="text-center"
          />
        </h2>
        <p className="text-[#B5B5B5] xl:text-3xl w-[70%] mt-20 text-center">
          We are crazily conscious about what we create for you, thoughtful
          about what brand stories we share and smart about the techniques to
          grow your brand and make it the next big thing in the industry
        </p>
      </div>

      <div className="flex items-center justify-center mt-20 xl:hidden">
        <div className="border border-white rounded-full p-3">
          <img src={downSmall} alt="" loading="lazy"/>
        </div>
      </div>
    </div>
  );
};

export default ProjectHero;
