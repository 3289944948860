import React from "react";
import PropTypes from "prop-types";
import Marquee from "react-fast-marquee";

const TextMarquee = ({ text, className }) => {
  const repeatedText = text.repeat(10);
  return (
    <div className={`flex flex-col gap-y-1 ${className}`}>
      <Marquee
        loop={0}
        speed={
          window.innerWidth < 768 ? 50 : window.innerWidth < 1024 ? 75 : 100
        }
      >
        <span className="text-themeBlue text-3xl xl:text-7xl font-bold leading-none">
          {repeatedText}
        </span>
      </Marquee>
      <Marquee
        loop={0}
        speed={
          window.innerWidth < 768 ? 75 : window.innerWidth < 1024 ? 100 : 150
        }
        direction="right"
      >
        <span className="text-themeBlue text-3xl xl:text-7xl font-bold leading-none">
          {repeatedText}
        </span>
      </Marquee>
    </div>
  );
};

TextMarquee.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextMarquee;
