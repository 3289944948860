import React, { useState } from "react"
import open from "../../assets/ContactUs/AddQuestion.svg"
import close from "../../assets/ContactUs/Close.svg"

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null)

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  const data = [
    {
      question: "Why choose us for design & branding projects?",
      answer:
        "Choose Slashon for creativity, reliability, and results. We breathe life into your brand with our unique visuals that resonate with your brand’s personality, and instantly connect with your audience. Our attention to detail ensures your brand's story shines through every design. Our approach? We unveil what's unseen, unheard, and unfelt.",
    },
    {
      question: "How much does it cost to work with us?",
      answer:
        "Our pricing is tailored to your project's specific needs and goals. Simply provide details about your project, and we'll offer a transparent breakdown of costs that align with your budget, ensuring the best results for your investment.",
    },
    {
      question: "How can our strategies bring a change in your business?",
      answer:
        "Our strategies are customized blueprints, not one-size-fits-all plans. Designed uniquely for each brand, they tackle business challenges head-on. With research, creativity, and industry insights, we drive real results, boosting brand visibility and customer engagement to impact your bottom line.",
    },
    {
      question: "Will marketing actually grow the business, how?",
      answer:
        "The answer is extensive, but in short, marketing is a powerful tool for numerical, insightful, and quantifiable business growth. Our growth solutions offer various strategies tailored to elevate your marketing efforts, driving your business from level one to level x. The results are realistic, proven, tracked and analyzed by our team to yours.",
    },
    {
      question: "Are we a design or marketing agency?",
      answer:
        "We're a full-service creative agency providing design, branding & growth solutions. Whether you need stunning visuals to captivate your audience or strategic marketing campaigns to elevate your brand, we offer comprehensive solutions to meet your needs and achieve measurable results.",
    },
  ]

  return (
    <div className="px-5 xl:px-24 py-20 xl:py-28">
      {data.map((e, index) => (
        <div key={index}>
          <div
            className="flex gap-8 justify-between items-center py-4 border-t-[1px] border-lightBlack/50 "
            onClick={() => toggleFAQ(index)}
          >
            <h2 className="text-lightBlack xl:text-xl font-bold">
              {e?.question}
            </h2>
            <img
              src={activeIndex === index ? close : open}
              alt=""
              className="w-6 h-6"
              loading="lazy"
            />
          </div>
          <div
            className={`overflow-hidden transition-all duration-700 ${
              activeIndex === index ? "max-h-96" : "max-h-0"
            }`}
          >
            <p className="text-lightBlack text-sm xl:text-base pb-4 ">
              {e?.answer}
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default FAQs
