import React, { useEffect, useState, useRef } from "react"
import ChaiCharcha from "../components/home/ChaiCharcha"
import SliderHome from "../components/home/SliderHome"
import FAQs from "../components/home/FAQs"
import WeAreSlashon from "../components/home/WeAreSlashon"
import Stories from "../components/home/Stories"
import Testimonial from "../components/home/Testimonial"
import Design from "../components/home/Design"
import TextMarquee from "../components/common/TextMarquee"
import SlashonShowreel from "../assets/Home page work gifs/showreel.webm"
import downSmall from "../assets/HomeIcons/mobile-down-arrow.svg"
import { VscMute, VscUnmute } from "react-icons/vsc"
import MetaDecorator from "../components/MetaDecorator"

const Home = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isMuted, setIsMuted] = useState(true)
  const [isButtonVisible, setIsButtonVisible] = useState(false)
  const videoRef = useRef(null)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const video = videoRef.current
      if (video && window.innerWidth >= 1240) {
        // Only apply animation for xl screens
        const scrollY = window.scrollY
        const maxScroll = 400
        const growSize = Math.min(1, scrollY / maxScroll)

        if (scrollY === 0) {
          video.style.transform = "scale(0.3)"
          video.style.marginTop = "-80px"
          video.style.marginBottom = "0px"
          setIsButtonVisible(false)
        } else {
          video.style.transform = `scale(${0.3 + growSize * 0.7}) translateY(${
            growSize * 50
          }px)`
          video.style.marginTop = `${growSize * 40}px`
          video.style.marginBottom = `${growSize * 20}px`
          setIsButtonVisible(growSize >= 0.95)
        }
      } else {
        setIsButtonVisible(true)
      }
    }

    const video = videoRef.current
    if (video && window.innerWidth >= 1240) {
      video.style.transform = "scale(0.2)"
      video.style.marginTop = "-80px"
      video.style.marginBottom = "0px"
    } else {
      video.style.transform = "scale(1)"
      video.style.marginTop = "0px"
      video.style.marginBottom = "0px"
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const toggleMute = () => {
    const video = videoRef.current
    if (video) {
      video.muted = !video.muted
      setIsMuted(video.muted)
    }
  }

  return (
    <div className="overflow-hidden relative">
      <MetaDecorator
        title="Slashon: Your brand’s creative partner"
        desc="A creative agency fulfilling design, branding and marketing needs with a dedicated mission to grow startups, SMEs and leading brands"
      />

      {/* themebluegreen gradient */}
      <div className="absolute md:h-[15%] md:w-[50%] md:top-[13%] md:right-[-30%]  md:blur-[210px] h-[211px] w-[211px] blur-[64px] right-[-10%] top-[7%] rounded-full bg-themebluegreen"></div>
      {/* themeYellow gradient  */}
      <div className="absolute h-[211px] md:h-[26%] top-[-20px] w-[211px] md:w-[63%] left-[-10%] md:top-[-25%] md:left-[-15%] blur-[64px] md:blur-[150px] rounded-full bg-themeYellow"></div>
      <div
        className={`transition-all duration-1000 ease-out 
        ${isLoaded ? "scale-100 opacity-100" : "scale-90 opacity-0"}
        `}
      >
        <div className="xl:mx-40 mt-[220px] flex justify-center items-center flex-col ">
          <h1 className="text-lightBlack text-center font-extrabold leading-[35px] xl:leading-[60px] text-[27px] xl:text-[54px] max-w-4xl mx-auto">
            We are a New Age Creative Agency offering Unique Solutions to give
            you the best Digital Experiences
          </h1>
          <p className="text-themeBlue text-center font-bold text-lg xl:text-3xl mt-8 ">
            Your partner to
            <br className="md:hidden" /> Create, Innovate & Strategise
          </p>
          <div className="flex items-center justify-center mt-10 xl:hidden">
            <div className="border border-black rounded-full p-3">
              <img src={downSmall} alt="" loading="lazy" />
            </div>
          </div>
        </div>
        <div className="video-container w-full mt-40 xl:mt-0 xl:mx-auto px-4 max-w-7xl relative">
          <video
            className="transition-all duration-500 ease-in-out border-2 transform rounded-[20px] xl:rounded-[50px] xl:scale-30 scale-100"
            ref={videoRef}
            src={SlashonShowreel}
            muted={isMuted}
            controls
            loop
            playsInline
            disablePictureInPicture
            type="video/mp4"
            autoPlay
          >
            Your browser does not support the video tag.
          </video>
          <button
            onClick={toggleMute}
            className="absolute top-4 right-8 md:right-10 xl:top-20 xl:right-12 bg-black bg-opacity-50 text-white rounded-full p-2 xl:p-3"
            style={{
              transform: "scale(1)",
              display: isButtonVisible ? "block" : "none",
            }}
          >
            {isMuted ? (
              <VscMute className="h-6 w-6" />
            ) : (
              <VscUnmute className="h-6 w-6" />
            )}
          </button>
        </div>

        <WeAreSlashon />
        <SliderHome />

        <Stories />
        <Design />

        <FAQs />
        <Testimonial />

        <TextMarquee text="LETS CREATE TOGETHER. " />
        <ChaiCharcha />
      </div>
    </div>
  )
}

export default Home
