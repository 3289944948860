import { useState } from "react";
import { solutionData } from "../../data/Solutiondata.js";
import TextMarquee from "../common/TextMarquee";

const Solutiondetail = () => {
  const [opencontent, setOpencontent] = useState(null);

  const toggleDescription = (index) => {
    setOpencontent(opencontent === index ? null : index);
  };

  return (
    <>
      <TextMarquee text="UNIQUE SOLUTIONS FOR YOUR BRAND. " className="mt-12" />

      <div className="flex mt-36 xl:mt-56 mb-52  flex-col intermediate:flex-row justify-center gap-y-14 items-center intermediate:justify-around">
        <div className="flex flex-col  w-[85%]  intermediate:w-[27%] lg:w-[27%]">
          <span
            className="w-full text-lightBlack font-bold lg:text-5xl tablet:text-6xl  intermediate:text-5xl 
          md:text-4xl sm:text-4xl text-4xl leading-none"
          >
            Finding it difficult to make a choice? <br />
            <span className="font-semibold lg:text-2xl mt-4 intermediate:text-2xl md:text-xl sm:text-lg text-lg ">
              Well, these might help!
            </span>
          </span>
        </div>
        <div className="flex flex-col text-wrap lg:w-[65vh] sm:w-[50vh] px-9 xl:px-0 xl:z-10">
          {solutionData.map((item, id) => (
            <div key={id}>
              <div
                className="flex justify-between items-center py-4 border-t border-neutral-400 cursor-pointer"
                onClick={() => toggleDescription(id)}
              >
                <p className="font-bold text-base mb-0 w-[100%] text-start md:w-[90%] text-neutral-800">
                  {item.title}
                </p>
                <div className="w-6 h-6 bg-lightBlack rounded-full flex items-center justify-center text-white transition-all">
                  {opencontent === id ? "×" : "+"}
                </div>
              </div>
              <div
                className={`transition-all duration-500 ease-in-out ${
                  opencontent === id
                    ? "max-h-[500px] opacity-100"
                    : "max-h-0 opacity-0"
                } overflow-hidden`}
              >
                <p className="text-[15px] text-lightBlack mb-3 font-semibold">
                  {item.desc}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Solutiondetail;
