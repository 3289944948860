import React from "react";
import { aboutgroup } from "../../data/Aboutdata.js";
import TextMarquee from "../common/TextMarquee";

const Aboutgroup = () => {
  const isSmallDevice = window.matchMedia("(max-width: 650px)").matches;
  return (
    <>
      <div className="w-full h-28 absolute -mt-12 xl:-mt-16 rounded-t-[3rem] bg-white" />
      <div className="w-full h-full flex flex-col gap-y-24 items-center justify-center pt-16">
        <div className="h-auto w-[80%]">
          <span className="flex items-center mt-2">
            <span className="w-2 h-2 bg-black rounded-full mr-1"></span>
            <p className="font-semibold text-lg sm:text-xl md:text-2xl">
              Core Values
            </p>
          </span>
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mt-4">
            Our Unique Perspective Shaping
          </h1>
          <h1
            className="text-4xl sm:text-3xl md:text-5xl text-themeBlue 
          text-wrap font-bold mt-1"
          >
            How we Approach Challenges
          </h1>

          {aboutgroup.map(
            ({ title, desc, gif, imgPosition, isImage }, index) => (
              <div
                key={index}
                className="md:h-80 mt-12 pb-12 md:mt-20 md:pb-20 flex flex-col md:flex-row justify-between items-center 
              border-b-[0.5px] border-b-slate-500"
              >
                {isSmallDevice ? (
                  <div>
                    <div className="w-full flex flex-col justify-center mb-4">
                      <span className="flex text-2xl">{title}</span>
                    </div>
                    {isImage && <div className="h-20"></div>}
                    <img
                      src={gif}
                      alt="gif"
                      className={`w-full mb-4`}
                      loading="lazy"
                    />
                    {isImage && <div className="h-12"></div>}
                    <div className="w-full flex flex-col justify-center items-start">
                      <p className="mt-4 font-semibold text-lg tracking-wide">
                        {desc}
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    {imgPosition ? (
                      <>
                        <img
                          src={gif}
                          alt="gif"
                          className="w-[45%] 2xl:w-[30%]"
                          loading="lazy"
                        />
                        <div className="w-[45%] mb-6 flex flex-col text-wrap justify-center items-end">
                          <span className="flex text-4xl text-end ">
                            {title}
                          </span>
                          <p
                            className="mt-4  phone:w-full md:text-sm  font-semibold text-xs 
                    tracking-wide text-end lg:text-lg"
                          >
                            {desc}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="w-[45%] flex mb-8 flex-col justify-center">
                          <span className="flex text-4xl ">{title}</span>
                          <p
                            className="mt-4  font-semibold text-xs  sm:text-sm md:text-sm lg:text-lg phone:w-full 
                    tracking-wide"
                          >
                            {desc}
                          </p>
                        </div>
                        <img
                          src={gif}
                          alt="gif"
                          className="w-[45%] 2xl:w-[30%]"
                          loading="lazy"
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            )
          )}
        </div>

        <TextMarquee text="AIMING, CHASING & SLASHING THE CHALLENGES. " />
      </div>
    </>
  );
};

export default Aboutgroup;
