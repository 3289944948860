import React from "react";
import { GoArrowDown } from "react-icons/go";
import VerticalTextCarousel from "../common/VerticalTextCaraousal";
const Solutionhero = () => {
  return (
    <>
      <div className="bg-lightBlack h-[105vh] relative overflow-hidden flex  flex-col mb-1 items-center justify-center">
        {/* themebluegreen gradient */}
        <div className="absolute h-[50%] w-[50%] top-[60%] right-[-30%]  blur-[192px] rounded-full bg-themebluegreen"></div>
        {/* themeYellow gradient  */}
        <div className="absolute h-[63%] w-[63%] top-[-25%] left-[-30%]  blur-[192px] rounded-full bg-themeYellow"></div>
        <div className="h-[50%] w-[80%] flex flex-col items-center gap-3 ">
          <h1 className="text-white text-2xl font-bold mt-12 text-wrap sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 3xl:text-8xl">
            We tackle problems with
          </h1>
          <div className="h-16 xl:h-32  w-full text-center">
            <VerticalTextCarousel
              textList={["Passion", "Purpose", "Perspective"]}
              fontSize="phone:text-6xl text-5xl xl:text-8xl"
              fontWeight="font-black"
              justifyContent="justify-center"
              textAlign="text-center"
            />
          </div>
          <p className="text-[#B5B5B5] text-lg w-[40vh] sm:w-[75vh]  sm:text-xl  xl:text-3xl mt-10 text-center">
            Our focus lies in giving life to your ideas by providing tailored
            solutions for your exceptional needs
          </p>
        </div>
        <div className="border-[1px] mt-4 border-white md:hidden rounded-full w-10 text-white h-10 flex justify-center items-center text-2xl">
          <GoArrowDown />
        </div>
      </div>
    </>
  );
};

export default Solutionhero;
