import React, { useMemo } from "react";

import Marquee from "react-fast-marquee";

import scroll1 from "../../assets/BrandLogospng/slashon.agencyworkfor2mynds.png";
import scroll2 from "../../assets/BrandLogospng/slashon.agencyworkforAugnito.png";
import scroll3 from "../../assets/BrandLogospng/slashon.agencyworkforAuxitech.png";
import scroll4 from "../../assets/BrandLogospng/slashon.agencyworkforCashkaro.png";
import scroll5 from "../../assets/BrandLogospng/slashon.agencyworkforCentrehive.png";
import scroll6 from "../../assets/BrandLogospng/slashon.agencyworkforEarlysteps.png";
import scroll7 from "../../assets/BrandLogospng/slashon.agencyworkforMakhanawalas.png";
import scroll8 from "../../assets/BrandLogospng/slashon.agencyworkforMarkSafety.png";
import scroll9 from "../../assets/BrandLogospng/slashon.agencyworkforMotherdairy.png";
import scroll10 from "../../assets/BrandLogospng/slashon.agencyworkforThePropertyExperts.png";
import scroll11 from "../../assets/BrandLogospng/slashon.agencyworkforTherising.png";
import scroll12 from "../../assets/BrandLogospng/slashon.agencyworkforVenusacademy.png";

const SliderHome = () => {
  const autoScrollItems = useMemo(
    () => [
      scroll1,
      scroll2,
      scroll3,
      scroll4,
      scroll5,
      scroll6,
      scroll7,
      scroll8,
      scroll9,
      scroll10,
      scroll11,
      scroll12,
    ],
    []
  );

  return (
    <div className="py-3 max-w-full overflow-hidden">
      <Marquee gradient={false} speed={100}>
        {autoScrollItems.map((image, index) => (
          <div key={index} className="flex items-center justify-center">
            <img
              className="h-14 md:h-20 xl:h-32 w-auto px-5 xl:px-10"
              src={image}
              alt=""
              loading="lazy"
            />
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default SliderHome;
