import React from "react";
import ProjectHero from "../components/projects/ProjectHero";
import ProjectsGroup from "../components/projects/ProjectsGroup";
import ImageCarousel from "../components/projects/ImageCarousel";
import ProjectCollab from "../components/projects/ProjectCollab";
import MetaDecorator from "../components/MetaDecorator";

const Projects = () => {
  return (
    <div className="relative overflow-hidden">
      <MetaDecorator
        title="Explore some best works by Slashon Agency"
        desc="Our portfolio is a blend of brands from startups, SMEs and leading players that includes designs and strategies that helped them grow drastically. Explore to know more!"
      />
      <ProjectHero />
      <ProjectsGroup />
      <ImageCarousel />
      <ProjectCollab />
    </div>
  );
};

export default Projects;
