import Branding from "../assets/Solutions Page gifs/Branding.gif";
import Design from "../assets/Solutions Page gifs/Design.gif";
import Growth from "../assets/Solutions Page gifs/Growth.gif";

// SolutionWork
export const sections = [
  {
    heading: "Design",
    desc: "At Slashon, we're your partners in crafting compelling design solutions that propel your brand forward. We believe in creating jaw-dropping visuals and digital design experiences that ensure your online presence; we also enhance the touch and feel of print materials to create a lasting impression on the brand identity.",
    items: [
      {
        title: "Digital",
        items: [
          "Email Newsletters",
          "Social Media Creatives",
          "UI/UX",
          "Illustrations",
          "Motion Graphics",
        ],
      },
      {
        title: "Print",
        items: [
          "Brochures & Flyers",
          "Packaging",
          "Out of Home Advertising",
          "Marketing Material",
          "Business Stationary",
        ],
      },
    ],
    imgPosition: false,
    gif: Design,
  },
  {
    heading: "Branding",
    desc: "Our aim is to bring into reality the story, voice, and personality of your brand. To reflect a brand identity that you care about, craft a strong brand message that you always wanted to convey, and create a lasting brand experience for your audience is what we focus on.",
    items: [
      {
        title: "Brand Strategy",
        items: [
          "Go-to-market Strategy",
          "Competitive Analysis",
          "Brand Positioning",
        ],
      },
      {
        title: "Brand Identity",
        items: ["Logo Design", "Brand Guidelines", "Brand Voice"],
      },
      {
        title: "Brand Messaging",
        items: ["Brand Storytelling", "Copywriting", "Content Writing"],
      },
      {
        title: "Brand Experience",
        items: [
          "Customer Journey Mapping",
          "Customer Experience Plan",
          "Analysis & Strategy",
        ],
      },
    ],
    imgPosition: true,
    gif: Branding,
  },
  {
    heading: "Growth",
    desc: "Our strategists and marketers constantly strive to relentlessly work on upscaling your brand with reach, relations, and results. Measuring, analyzing, and optimizing with our exceptional marketing solutions, AI innovations, and strategies that grow your brand at an unconventional rate.",
    items: [
      {
        title: "Email Marketing",
        items: [
          "Newsletter Campaign",
          "Content & Strategy",
          "Reporting & Analytics",
        ],
      },
      {
        title: "SEO",
        items: [
          "Keyword Research & Strategy",
          "On-page Optimisation",
          "Reporting & Analytics",
        ],
      },
      {
        title: "Pay-Per-Click Advertising",
        items: ["Google Ads", "Social Media Ads", "Meta Ads"],
      },
      {
        title: "Social Media Marketing",
        items: [
          "Performance Analysis",
          "SMM Strategy",
          "Social Media Optimisation",
        ],
      },
    ],
    imgPosition: false,
    gif: Growth,
  },
];

// Solutiondetail.js
export const solutionData = [
  {
    title: "Why choose us for Design & Branding projects?",
    desc: "Choose us for creativity, reliability, and results. We bring life to your brand through unique visuals that resonate with your audience. Our attention to detail ensures your brand's story shines.",
  },
  {
    title: "How much does it cost to work with us?",
    desc: "Our pricing varies depending on the scope and complexity of the project. We offer customized packages to fit different needs and budgets. Contact us for a detailed quote.",
  },
  {
    title: "How can our Strategies bring a change in your Business?",
    desc: "Our strategies focus on data-driven insights and innovative approaches. We create tailor-made plans that align with your business goals, driving growth, engagement, and long-term success.",
  },
  {
    title: "Will Marketing actually grow the Business, How?",
    desc: "Marketing, when done right, can significantly boost your business. We target the right audience with effective messaging, helping to increase brand awareness, customer acquisition, and retention.",
  },
  {
    title: "Are we a Design or Marketing Agency?",
    desc: "We are both! Our team of experts offers a combination of design and marketing services to provide a comprehensive solution for your brand, ensuring consistency and success across all platforms.",
  },
];
