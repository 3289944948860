import React from "react";

const ButtonVariant = {
  GREEN: "green",
  BLUE: "blue",
};

const OutlinedArrowButton = ({
  variant = ButtonVariant.BLUE,
  buttonText,
  textSize = "text-base",
  onClick,
  className,
  showLoader = false,
}) => {
  const borderColor =
    variant === ButtonVariant.GREEN ? "border-themeGreen" : "border-themeBlue";
  const textColor =
    variant === ButtonVariant.GREEN
      ? "text-black xl:text-themeGreen xl:group-hover:text-black"
      : "text-themeBlue group-hover:text-white";
  const hoverFillColor =
    variant === ButtonVariant.GREEN ? "bg-themeGreen" : "bg-themeBlue";

  const arrowColor = variant === ButtonVariant.GREEN ? "#000000" : "#3D4DFF";

  return (
    <div
      className={`group relative flex justify-center items-center border-[1px] w-fit rounded-[50px] py-[6px] px-[14px] gap-[6px] overflow-hidden ${borderColor} ${className} ${
        showLoader
          ? "bg-themeBlue"
          : variant === ButtonVariant.BLUE
          ? "bg-themeBlue xl:bg-transparent"
          : "bg-themeGreen xl:bg-transparent"
      }`}
    >
      <div className="absolute inset-0 animate-shimmer xl:hidden">
        <div className="absolute h-full w-10 bg-gradient-to-r from-transparent via-white to-transparent skew-x-[-30deg] opacity-30" />
      </div>

      <div
        className={`absolute inset-0 transition-all duration-300 ease-in-out rounded-[80px] ${
          showLoader ? "bg-themeBlue" : hoverFillColor
        } scale-0 xl:group-hover:scale-100 origin-bottom-left hidden xl:block z-0`}
      ></div>

      {showLoader ? (
        <div className={`relative z-10 px-10 xl:py-1`}>
          <div className="loader border-t-transparent border-solid border-4 border-white rounded-full w-6 h-6 animate-spin"></div>
        </div>
      ) : (
        <>
          <button
            className={`relative z-20 ${textSize} xl:text-lg font-medium transition-colors duration-300 ${
              variant === ButtonVariant.BLUE
                ? "text-white xl:text-themeBlue xl:group-hover:text-white"
                : textColor
            }`}
            onClick={onClick}
          >
            {buttonText}
          </button>

          <svg
            width="16"
            height="11"
            viewBox="0 0 16 11"
            fill={arrowColor}
            xmlns="http://www.w3.org/2000/svg"
            className={`relative z-10 transition-all duration-200 ${
              variant === ButtonVariant.BLUE
                ? "fill-white xl:fill-themeBlue xl:group-hover:fill-white"
                : "fill-black xl:fill-themeGreen xl:group-hover:fill-black"
            }`}
          >
            <path
              id="Vector"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.34186 8.92498C9.2486 9.01188 9.1738 9.11667 9.12192 9.23311C9.07004 9.34954 9.04214 9.47524 9.03989 9.60269C9.03764 9.73014 9.06109 9.85674 9.10883 9.97493C9.15657 10.0931 9.22763 10.2005 9.31776 10.2906C9.4079 10.3808 9.51527 10.4518 9.63346 10.4996C9.75166 10.5473 9.87826 10.5708 10.0057 10.5685C10.1332 10.5663 10.2589 10.5384 10.3753 10.4865C10.4917 10.4346 10.5965 10.3598 10.6834 10.2665L14.798 6.15326L15.4687 5.48248L14.798 4.81169L10.6847 0.698412C10.5057 0.525418 10.266 0.429628 10.0171 0.431672C9.76825 0.433717 9.53012 0.533433 9.35405 0.709344C9.17797 0.885254 9.07803 1.12328 9.07575 1.37217C9.07347 1.62105 9.16903 1.86087 9.34186 2.03998L11.8351 4.53326L1.69922 4.53326C1.44747 4.53326 1.20603 4.63326 1.02802 4.81128C0.850007 4.98929 0.75 5.23073 0.75 5.48247C0.75 5.73422 0.850007 5.97566 1.02802 6.15367C1.20603 6.33169 1.44747 6.43169 1.69922 6.43169L11.8351 6.43169L9.34186 8.92498Z"
              className={`transition-all duration-200 fill-current ${
                variant === ButtonVariant.BLUE
                  ? "text-white xl:text-themeBlue xl:group-hover:text-white"
                  : textColor
              }`}
            />
          </svg>
        </>
      )}
    </div>
  );
};

export { ButtonVariant, OutlinedArrowButton };
