import vid1 from "../assets/Home page work gifs/propertyexperts.webm";
import vid2 from "../assets/Home page work gifs/cashkaro.webm";
import vid3 from "../assets/Home page work gifs/Motherdairy.webm";
import vid4 from "../assets/Home page work gifs/marksafety.webm";

import propertyExpertImg1 from "../assets/projectSection/ProjectPropertyExpert/imgPropertyExperta.webp";
import propertyExpertImg2 from "../assets/projectSection/ProjectPropertyExpert/imgPropertyExpertb.webp";
import propertyExpertImg3 from "../assets/projectSection/ProjectPropertyExpert/imgPropertyExpertc.webp";
import propertyExpertImg4 from "../assets/projectSection/ProjectPropertyExpert/imgPropertyExpertd.webp";
import propertyExpertImg5 from "../assets/projectSection/ProjectPropertyExpert/imgPropertyExpertf.webp";
import propertyExpertImg6 from "../assets/projectSection/ProjectPropertyExpert/imgPropertyExperte.webp";

import cashkaroImg1 from "../assets/projectSection/ProjectCashKaro/imgCashKaroa.webp";
import cashkaroImg2 from "../assets/projectSection/ProjectCashKaro/imgCashKarob.webp";
import cashkaroImg3 from "../assets/projectSection/ProjectCashKaro/imgCashKaroc.webp";
import cashkaroImg4 from "../assets/projectSection/ProjectCashKaro/imgCashKarod.webp";
import cashkaroImg5 from "../assets/projectSection/ProjectCashKaro/imgCashKarog.webp";
import cashkaroImg6 from "../assets/projectSection/ProjectCashKaro/imgCashKaroh.webp";
import cashkaroImg7 from "../assets/projectSection/ProjectCashKaro/imgCashKaroi.webp";
import cashkaroImg8 from "../assets/projectSection/ProjectCashKaro/imgCashKaroj.webp";

import cashkaroImg9 from "../assets/projectSection/ProjectCashKaro/imgCashKaroq.webp";
import cashkaroImg10 from "../assets/projectSection/ProjectCashKaro/imgCashKaror.webp";
import cashkaroImg11 from "../assets/projectSection/ProjectCashKaro/imgCashKaros.webp";
import cashkaroImg12 from "../assets/projectSection/ProjectCashKaro/imgCashKarok.webp";
import cashkaroImg13 from "../assets/projectSection/ProjectCashKaro/imgCashKarol.webp";

import motherDairyImg1 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairya.webp";
import motherDairyImg2 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairyb.webp";
import motherDairyImg3 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairyc.webp";
import motherDairyImg4 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairyd.webp";
import motherDairyImg5 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairye.webp";
import motherDairyImg6 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairyf.webp";
import motherDairyImg7 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairyg.webp";
import motherDairyImg8 from "../assets/projectSection/ProjectMotherDairy/imgMotherDairyh.webp";

import marksafetyImg1 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetya.webp";
import marksafetyImg2 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyb.webp";
import marksafetyImg3 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyl.webp";
import marksafetyImg4 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyc.webp";
import marksafetyImg5 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyd.webp";
import marksafetyImg6 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetye.webp";
import marksafetyImg7 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyf.webp";
import marksafetyImg8 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyg.webp";
import marksafetyImg9 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyh.webp";
import marksafetyImg10 from "../assets/projectSection/ProjectMarkSafety/imgMarkSafetyi.webp";

import marksafetyGif from "../assets/projectSection/ProjectMarkSafety/imgMarkSafety.gif";

export const projectData = [
  {
    id: 1,
    company: "Property Experts",
    industry: "Real Estate",
    companySize: "11-50 Employees",
    description:
      "Assisting Estate Agents in delivering top-notch service for their clients",
    video: vid1,
    categories: ["Web Design", "Branding"],
    work: [
      "Logo Design",
      "Brand Messaging",
      "Social Media",
      "Brand Identity",
      "Google & Meta Ads",
    ],
    path: "propertyexperts",
    projectImages: [
      propertyExpertImg1,
      propertyExpertImg2,
      propertyExpertImg3,
      propertyExpertImg4,
      propertyExpertImg5,
      propertyExpertImg6,
    ],
  },
  {
    id: 2,
    company: "CashKaro",
    industry: "Fintech",
    companySize: "500-1000 Employees",
    description:
      "India's Largest Cashback App with over 1500+ online shopping sites",
    video: vid2,
    categories: ["Web Design", "Branding"],
    work: [
      "Email Marketing",
      "Brand Messaging",
      "Art Direction",
      "Social Media",
    ],
    path: "cashkaro",
    projectImages: [
      cashkaroImg1,
      cashkaroImg2,
      cashkaroImg3,
      cashkaroImg4,
      cashkaroImg5,
      cashkaroImg6,
      cashkaroImg7,
      cashkaroImg8,
      cashkaroImg9,
      cashkaroImg10,
      cashkaroImg11,
      cashkaroImg12,
      cashkaroImg13,
    ],
  },
  {
    id: 3,
    company: "Mother Dairy",
    industry: "FMCG",
    companySize: "1K-2K Employees",
    description: "Created personalised product for ICC Worldcup's Schedule",
    video: vid3,
    categories: ["Web Design", "Branding"],
    work: ["Creative Design", "Brand Messaging", "Brand Strategy"],
    path: "motherdairy",
    projectImages: [
      motherDairyImg1,
      motherDairyImg2,
      motherDairyImg3,
      motherDairyImg4,
      motherDairyImg5,
      motherDairyImg6,
      motherDairyImg7,
      motherDairyImg8,
    ],
  },
  {
    id: 4,
    company: "Mark Safety",
    industry: "Fire & Safety",
    companySize: "11-50 Employees",
    description: "Deploying world-class industrial fire & safety equipment",
    video: vid4,
    categories: ["Web Design", "Branding"],
    work: [
      "Logo Design",
      "Brand Messaging",
      "Website Design",
      "Brand Identity",
      "Google & Meta Ads",
      "Creative Campaign",
    ],
    path: "marksafety",
    projectImages: [
      marksafetyImg1,
      marksafetyImg2,
      marksafetyImg3,
      marksafetyImg4,
      marksafetyImg5,
      marksafetyImg6,
      marksafetyImg7,
      marksafetyImg8,
      marksafetyImg9,
      marksafetyImg10,
      marksafetyGif,
    ],
  },
];
