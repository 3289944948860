import React from "react";
import logo from "../assets/Slashon Logos/slashon.agency_white_logo.svg";
import { OutlinedArrowButton } from "./common/outlineArrowButton";
import { useNavigate } from "react-router-dom";
import RouteNames from "../RouteNames";

const ErrorRoute = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center h-auto my-36 justify-center gap-12 py-8 px-5 xl:px-40 ">
      <img src={logo} alt="Not Found" className="w-40 m-8" loading="lazy" />
      <h1 className="text-lightBlack text-4xl md:text-6xl font-bold">
        Looks like
        <span className="text-themeBlue"> you're lost</span>
      </h1>
      <p className="text-lightBlack text-lg font-medium">
        The page you are looking for does not exist.
      </p>
      <OutlinedArrowButton
        buttonText="Go to Home"
        onClick={() => navigate(RouteNames.HOME)}
      />
    </div>
  );
};

export default ErrorRoute;
