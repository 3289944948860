import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import {
  OutlinedArrowButton,
  ButtonVariant,
} from "../common/outlineArrowButton";
import { tabsContent } from "../../data/Homedata";
import arrow from "../../assets/HomeIcons/down-white.svg";
import RouteNames from "../../RouteNames";
const Design = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const contentRef = useRef(null);
  const [swipeAnimation, setSwipeAnimation] = useState(null);

  const handleTabClick = (index, reverse = false) => {
    if (contentRef.current && window.innerWidth > 1240) {
      const contentRefCurrent = contentRef.current;
      if (contentRefCurrent) {
        contentRefCurrent.style.transition = "transform 0.5s ease-out";
        contentRefCurrent.style.transform = reverse
          ? `translateY(${index * 100}%)`
          : `translateY(-${index * 100}%)`;
        setTimeout(() => {
          contentRefCurrent.style.transition = "none";
          contentRefCurrent.style.transform = "translateY(0)";
          setActiveTab(index);
        }, 500);
      }
    } else {
      setSwipeAnimation(index > activeTab ? "swipe-left" : "swipe-right");
      setTimeout(() => {
        setActiveTab(index);
        setSwipeAnimation(null);
      }, 300);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (activeTab < tabsContent.length - 1) {
        handleTabClick(activeTab + 1);
      }
    },
    onSwipedRight: () => {
      if (activeTab > 0) {
        handleTabClick(activeTab - 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  useEffect(() => {
    if (swipeAnimation) {
      setTimeout(() => setSwipeAnimation(null), 300);
    }
  }, [swipeAnimation]);

  return (
    <div className="relative xl:h-[800px] bg-lightBlack overflow-hidden flex flex-col xl:flex-row items-start pt-10 xl:p-10 xl:pb-4 rounded-t-3xl xl:rounded-t-none xl:px-12 xl:py-24 w-full">
      {/* themeBlueGreen gradient  */}
      <div className="absolute h-[50%] w-[50%] top-[-25%] left-[-35%]  blur-[192px] rounded-full bg-themebluegreen"></div>
      {/* themeYellow gradient  */}
      <div className="absolute h-[50%] w-[50%] top-[60%] right-[-35%]  blur-[192px] rounded-full bg-themeYellow"></div>

      <div className="flex flex-col gap-3 xl:pl-[5%] xl:w-1/2 xl:sticky">
        <div className="text-white px-4 xl:px-0 xl:pr-20">
          <div className="xl:flex items-center gap-4 hidden">
            <div className="h-1.5 w-1.5 bg-white rounded-full"></div>
            <p className="font-medium text-xl">Solutions</p>
          </div>
          <div className="text-4xl items-center text-center xl:text-start xl:text-5xl font-bold">
            <p className="mb-16 xl:mt-4 xl:mb-40 mx-auto xl:mx-0 max-w-[90%] xl:max-w-none">
              Offering Unique Solutions that help your Brand grow faster &
              better
            </p>
            <OutlinedArrowButton
              buttonText="View All Solutions"
              onClick={() => navigate(RouteNames.SOLUTIONS)}
              variant={ButtonVariant.GREEN}
              className="hidden xl:inline-flex whitespace-nowrap"
            />
          </div>
        </div>
      </div>
      <div className="w-20 flex"></div>
      {/* mobile tabs */}
      <div className="xl:hidden w-full px-4 flex flex-row justify-between items-center">
        {tabsContent.map((tab, index) => (
          <p
            key={index}
            className={`cursor-pointer text-2xl font-bold flex-1 text-center ${
              activeTab === index ? "text-themeGreen" : "text-white"
            }`}
            onClick={() => handleTabClick(index)}
          >
            {tab.title}
          </p>
        ))}
      </div>
      <div
        className="flex h-full justify-center item-center px-8 xl:pt-2 xl:px-0 xl:cursor-pointer xl:w-1/2"
        {...handlers}
      >
        <div className="flex h-full flex-col">
          <p className="text-2xl text-white hidden xl:block mr-8 mt-12">
            {(activeTab + 1).toString().padStart(2, "0")}.
          </p>

          <div className="hidden h-[60%] xl:flex flex-col justify-between my-12">
            <button
              className={`mb-2 ${
                activeTab === 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() =>
                activeTab > 0 && handleTabClick(activeTab - 1, true)
              }
              disabled={activeTab === 0}
            >
              <img
                src={arrow}
                alt="arrow"
                className="h-[40px] w-[40px] rounded-[40px] border-2 border-white p-2 rotate-180"
                loading="lazy"
              />
            </button>
            <div className="flex flex-col p-4">
              {tabsContent.map((_, index) => (
                <span
                  key={index}
                  className={`h-2 w-2 rounded-full my-1 ${
                    activeTab === index
                      ? "bg-themeGreen"
                      : "bg-white opacity-50"
                  }`}
                ></span>
              ))}
            </div>

            <button
              className={`mt-2 ${
                activeTab === tabsContent.length - 1
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              onClick={() =>
                activeTab < tabsContent.length - 1 &&
                handleTabClick(activeTab + 1)
              }
              disabled={activeTab === tabsContent.length - 1}
            >
              <img
                src={arrow}
                alt="arrow"
                className="h-[40px] w-[40px] rounded-[40px] border-2 border-white p-2"
                loading="lazy"
              />
            </button>
          </div>
        </div>

        <div ref={contentRef} className={`w-full ${swipeAnimation}`}>
          <div className="flex text-2xl items-center justify-between xl:text-7xl xl:gap-4 text-white font-bold leading-none xl:mt-4">
            <p className="text-themeGreen hidden xl:block">
              {tabsContent[activeTab].title}
            </p>
          </div>
          <p className="text-lg text-white hidden xl:block mt-4 xl:w-[80%]">
            {tabsContent[activeTab].description}
          </p>
          <div className="w-full h-[0.75px] bg-white opacity-25 mt-4 mb-8"></div>
          {tabsContent[activeTab].sections.map((section, index) => (
            <div
              key={index}
              className="flex flex-col items-start md:px-[20%] xl:px-0 my-10 xl:my-15 justify-center"
            >
              <p className="text-2xl mb-3 xl:mb-0 text-white font-bold leading-none">
                {section.title}
              </p>
              <div className="text-lg text-lightGrey flex flex-wrap items-center gap-2 xl:gap-4 xl:mt-8">
                {section.points.map((point, pointIndex) => (
                  <React.Fragment key={pointIndex}>
                    <p className="hover:text-themeGreen transition-colors duration-300 ease-in-out">
                      {point}
                    </p>
                    {pointIndex < section.points.length - 1 && (
                      <div className="h-1.5 w-1.5 bg-white rounded-full"></div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col items-center w-full xl:hidden">
        <div className="flex justify-center my-8 xl:hidden">
          {tabsContent.map((_, index) => (
            <span
              key={index}
              className={`h-2 w-2 rounded-full mx-1 ${
                activeTab === index ? "bg-themeGreen" : "bg-white opacity-50"
              }`}
            ></span>
          ))}
        </div>
        <OutlinedArrowButton
          buttonText="View All Solutions"
          onClick={() => {
            return navigate(RouteNames.SOLUTIONS);
          }}
          variant={ButtonVariant.GREEN}
          className="xl:hidden mb-8 whitespace-nowrap"
        />
      </div>
    </div>
  );
};

export default Design;
