export const tabsContent = [
  {
    id: "design",
    title: "Design",
    description:
      "Optimizing your visual game with creative trends and Graphic Design techniques",
    sections: [
      {
        title: "Digital",
        points: [
          "Logo design",
          "Social media creatives",
          "Illustrations",
          "Motion graphics",
          "Video editing",
          "Website Design",
        ],
      },
      {
        title: "Print",
        points: ["Brochures & Flyers", "Packaging", "OOH"],
      },
    ],
  },
  {
    id: "branding",
    title: "Branding",
    description:
      "Narrating the real story & elevating your identity to make it standout in crowded market",
    sections: [
      {
        title: "Brand Identity",
        points: ["Brand Guidelines", "Brand Voice"],
      },
      {
        title: "Brand Strategy",
        points: [
          "Go-to-market Strategy",
          "Competitive Analysis",
          "Brand Positioning",
        ],
      },
      {
        title: "Brand Messaging",
        points: ["Brand Storytelling", "Copywriting", "Content Writing"],
      },
    ],
  },
  {
    id: "growth",
    title: "Growth",
    description:
      "Giving your business scope to grow with realtime Measurable Metrics and Strategies",
    sections: [
      {
        title: "Social Media Marketing",
        points: [
          "Performance Analysis",
          "SMM Strategy",
          "Social Media Optimization",
        ],
      },
      {
        title: "Search Engine Optimization",
        points: ["Keyword Research & Strategy", "On-Page Optimization"],
      },
      {
        title: "Pay-Per-Click Advertising",
        points: ["Google Ads", "Meta Ads", "Instagram Ads"],
      },
    ],
  },
];
