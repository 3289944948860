import React from "react";
import ProjectCard from "../common/ProjectCard";
import { projectData } from "../../data/projectData";

const ProjectsGroup = () => {
  return (
    <div>
      <div className="w-full h-28 absolute -mt-48 rounded-t-[3rem] bg-white" />
      <div className="w-full h-full flex flex-col gap-y-24 items-center justify-center ">
        <div className="h-auto ">
          <div className="xl:columns-2 gap-2 px-5 xl:px-[100px]">
            <h2 className="text-lightBlack font-bold text-4xl mb-10 text-start xl:hidden">
              Dive into our <br />
              Design & Brand Stories
            </h2>
            {projectData.map((e, index) => (
              <div key={e.company}>
                {index === getTextIndex() && (
                  <h2 className="text-lightBlack font-bold md:text-[41px] leading-tight mb-10 text-start hidden xl:block mx-16">
                    Dive into our <br />
                    Design & Brand Stories
                  </h2>
                )}
                <ProjectCard
                  video={e.video}
                  title={e.company}
                  titleType={e.industry}
                  description={e.description}
                  categories={e.categories}
                  projectPath={e.path}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  function getTextIndex() {
    if (projectData.length % 2 === 0) {
      return projectData.length / 2;
    }
    return (projectData.length + 1) / 2;
  }
};

export default ProjectsGroup;
