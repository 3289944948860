import React from "react"
import LetsConnect from "../components/contact/LetsConnect"
import FAQs from "../components/contact/FAQs"
import MetaDecorator from "../components/MetaDecorator"

const ContactUs = () => {
  return (
    <div className="overflow-hidden  relative">
      <MetaDecorator
        title="Contact Us | Get in touch with your creative partner"
        desc="Partner with us to drive your growth to the next level."
      />
      <div className="absolute h-[50%] w-[50%] top-[-25%] right-[-20%]  blur-[210px] rounded-full bg-themebluegreen"></div>
      {/* themeYellow gradient  */}
      <div className="absolute h-[63%] w-[63%] top-[50%] left-[-35%]  blur-[192px] rounded-full bg-themeYellow"></div>
      <LetsConnect />
      <FAQs />
    </div>
  )
}

export default ContactUs
