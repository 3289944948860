import React from "react"
import { useNavigate } from "react-router-dom"
import { OutlinedArrowButton } from "../common/outlineArrowButton"
import ProjectCard from "../common/ProjectCard";
import { projectData as data } from "../../data/projectData";
import RouteNames from "../../RouteNames";

const Stories = () => {
  const navigate = useNavigate()

  return (
    <div className="py-40">
      <div className="xl:columns-2 gap-2 px-5 xl:px-[100px]">
        <h2 className="text-lightBlack font-bold text-4xl mb-10 text-start md:pl-16 xl:hidden">
          Dive into our <br />
          Design & Brand Stories
        </h2>
        {data?.map((e, index) => (
          <div
            key={e.title}
            className="break-inside-avoid flex xl:block justify-center"
          >
            {index === 2 && (
              <h2 className="text-lightBlack font-bold md:text-[41px] mb-10 leading-tight text-start hidden xl:block mx-16">
                Dive into our <br />
                Design & Brand Stories
              </h2>
            )}
            <ProjectCard
              video={e.video}
              title={e.company}
              titleType={e.industry}
              description={e.description}
              categories={e.categories}
              projectPath={e.path}
            />
          </div>
        ))}
      </div>
      <div className="space-y-10 px-5 xl:px-[164px] xl:mt-10">
        <p className="font-medium xl:text-xl xl:max-w-[619px] ">
          Our partnership with budding startups to leading brands, makes us
          diversely approachable. Incorporating AI innovations helps us stand
          out and be a part of the loop of trends in the industry- we never miss
          out on what's important for you!
        </p>
        <OutlinedArrowButton
          buttonText="View All Projects"
          onClick={() => navigate(RouteNames.PROJECTS)}
        />
      </div>
    </div>
  )
}

export default Stories
