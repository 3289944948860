import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const VerticalTextCarousel = ({
  textList,
  fontSize = "text-5xl phone:text-6xl sm:text-7xl md:text-8xl text-start lg:text-9xl",
  fontWeight = "font-bold",
  textAlign = "text-center xl:text-left",
  justifyContent = "justify-center xl:justify-start",
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % textList.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [textList]);

  return (
    <div
      className={`h-16 md:h-28 xl:h-32 overflow-hidden flex items-center lg:items-start ${justifyContent}`}
    >
      <div className="relative w-full  h-full">
        <AnimatePresence initial={false}>
          <motion.div
            key={currentIndex}
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            exit={{ y: "-100%" }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className={`absolute inset-0 xl:flex items-center ${justifyContent}`}
          >
            <span
              className={`${fontWeight} ${fontSize} text-themeGreen ${textAlign}`}
            >
              {textList[currentIndex]}
            </span>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default VerticalTextCarousel;
