import React from "react";

import { OutlinedArrowButton } from "../common/outlineArrowButton";
import { useNavigate } from "react-router-dom";
import TextMarquee from "../common/TextMarquee";
import RouteNames from "../../RouteNames";
const ProjectCollab = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex flex-col justify-center items-center xl:pt-32 pt-20 px-5 xl:px-0">
        <h2 className="font-bold text-[35px] xl:text-[72px] text-center leading-none">
          Let’s start building your Brand <br className="hidden xl:block" />
          over
          <span className="text-themeBlue"> a Cup of Chai!</span>{" "}
        </h2>
        <h3 className="font-medium text-center xl:text-start xl:text-2xl mb-8 xl:mb-14 mt-5">
          We also don't mind Coffee & Conversations :P
        </h3>
        <OutlinedArrowButton
          buttonText={"Let’s Collaborate"}
          onClick={() => {
            navigate(RouteNames.CONTACT_US);
          }}
          className={"py-[8px] px-[12px] mt-4"}
        />
      </div>
      <TextMarquee text="LETS CREATE TOGETHER. " className="my-20" />
    </div>
  );
};

export default ProjectCollab;
