import React from "react";
import { useNavigate } from "react-router-dom";
import { OutlinedArrowButton } from "../common/outlineArrowButton";
import RouteNames from "../../RouteNames";

const FAQs = () => {
  const navigate = useNavigate();

  return (
    <div className="-mt-1 xl:-mt-0">
      <div className="flex flex-col xl:flex-row justify-between px-5 xl:px-10 py-20 rounded-t-3xl xl:rounded-t-none bg-white xl:py-32 text-lightBlack container mx-auto">
        <p className="font-bold text-[30px] text-center xl:text-start xl:text-[54px] leading-none ">
          Have a Question <br />
          in mind?
        </p>
        <div className="space-y-8">
          <p className="font-medium text-center xl:text-start xl:text-xl xl:max-w-[619px] mt-10 xl:mt-0">
            We consider communication is the key, and asking questions is the
            first step to it. Our team is easily approachable and excited to
            help with anything, till then you might want to have a quick look at
            some questions people ask us.
          </p>
          <div className="flex justify-center items-center xl:block">
            {" "}
            <OutlinedArrowButton
              buttonText="All FAQs"
              onClick={() => navigate(RouteNames.CONTACT_US)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
