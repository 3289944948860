import React, { useState, useEffect } from "react";

const ProjectSectionHero = ({ currentProject }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 425);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!currentProject) return null;

  const { company, description, industry, work, companySize } = currentProject;

  return (
    <div className=" p-0 lg:p-8 mb-8  w-full xl:w-[85%] flex flex-col">
      <div className="p-4 xl:p-6 flex flex-col lg:flex-row justify-between gap-8">
        <div className="flex flex-col w-full xl:w-[70%] xl:pr-8">
          <h1 className="font-bold text-themeBlue mb-2 text-4xl sm:text-5xl lg:text-6xl">
            {company}
          </h1>
          <p className="text-black text-xl xl:text-3xl sm:text-2xl lg:text-[40px] mb-8 w-full font-medium leading-tight tracking-[-1px]">
            {description}
          </p>
        </div>
        <div className="flex flex-row w-full lg:w-1/2 text-lg sm:text-xl gap-x-8 lg:gap-x-12 font-bold text-black text-left">
          <div className="flex flex-col gap-y-4">
            <h3>Industry</h3>
            <h3>Company Size</h3>
          </div>
          <div className="flex flex-col gap-x-24 gap-y-4">
            <p className="font-medium">{industry}</p>
            <p className="font-medium">{companySize}</p>
          </div>
        </div>
      </div>
      <div className="p-4 xl:p-6 xl:w-[55%]">
        <h4 className="font-bold text-lg mb-2">How did we help:</h4>
        <span
          className={`w-full mt-5 ${
            isSmallScreen
              ? "grid grid-cols-2 md:grid-cols-3 gap-2"
              : "flex flex-wrap gap-2"
          }`}
        >
          {work?.map((item, index) => (
            <span
              key={index}
              className="bg-gray-200 text-black text-center text-sm xl:text-base  font-medium px-3 py-1.5 xl:px-4 xl:py-1 p-2 rounded-full whitespace-nowrap"
            >
              {item}
            </span>
          ))}
        </span>
      </div>
    </div>
  );
};

export default ProjectSectionHero;
