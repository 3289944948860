import React from "react";
import { sections } from "../../data/Solutiondata.js";
import { OutlinedArrowButton } from "../common/outlineArrowButton.jsx";
import { useNavigate } from "react-router-dom";
import RouteNames from "../../RouteNames";

const SolutionWork = () => {
  const navigate = useNavigate();
  const isXL = window.matchMedia("(min-width: 1240px)").matches;

  return (
    <>
      <div className="w-full h-16 xl:h-28 absolute -mt-11 rounded-t-[3rem] bg-white" />
      <svg width="0" height="0" style={{ position: "absolute" }}>
        <defs>
          <clipPath id="solutionClipPath" clipPathUnits="objectBoundingBox">
            <path d="M1 0.0604C1 0.0271 0.9728 0 0.9396 0H0.0604C0.0271 0 0 0.0271 0 0.0604V0.8049C0 0.8382 0.0271 0.8653 0.0604 0.8653H0.4525C0.4858 0.8653 0.5129 0.8924 0.5129 0.9257V0.9327V0.9396C0.5129 0.9729 0.54 1 0.5733 1H0.9396C0.9728 1 1 0.9729 1 0.9396V0.0604" />
          </clipPath>
        </defs>
      </svg>
      <div className="flex xl:mt-36 mt-8 flex-col gap-y-20 xl:gap-y-0 items-center w-full rounded-t-[3rem] overflow-hidden ">
        {/* themebluegreen gradient */}
        <div className="absolute h-[15%] w-[50%] top-[75%] right-[-30%] blur-[192px] rounded-full hidden md:block bg-themebluegreen"></div>
        <div className="absolute h-[15%] w-[50%] top-[30%] left-[-30%] blur-[192px] rounded-full hidden md:block bg-themebluegreen"></div>
        {/* themeYellow gradient */}
        <div className="absolute h-[15%] w-[73%] top-[73%] left-[-45%] blur-[192px] rounded-full hidden md:block bg-themeYellow"></div>
        <div className="absolute h-[15%] w-[73%] top-[50%] right-[-50%] blur-[192px] rounded-full hidden md:block bg-themeYellow"></div>
        <div className="absolute h-[10%] w-[73%] top-[19.5%] right-[-40%] blur-[192px] rounded-full hidden md:block bg-themeYellow"></div>
        {sections.map(
          ({ title, desc, heading, items, imgPosition, gif }, index) => (
            <div
              key={index}
              className={`flex ${
                isXL && imgPosition ? "flex-col-reverse" : "flex-col"
              } xl:flex-row  mb-10 justify-around items-center w-[80%]`}
            >
              {isXL && imgPosition ? (
                <>
                  <div className="relative">
                    <div
                      className="rounded-[40px] overflow-hidden transform xl:scale-x-[-1]"
                      style={{
                        clipPath: "url(#solutionClipPath)",
                      }}
                    >
                      <img
                        className="object-cover transform scale-x-[-1]"
                        alt="Solution Gif"
                        src={gif}
                        loading="lazy"
                      />
                    </div>
                    <div className="absolute xl:right-1 bottom-1">
                      <OutlinedArrowButton
                        buttonText={"Featured Projects"}
                        textSize="text-sm"
                        onClick={() => {
                          navigate(RouteNames.PROJECTS);
                        }}
                        className={
                          "xl:py-[6px] xl:px-[16px] phone:px-8 phone:py-2 py-[4px] px-[6px] mt-12"
                        }
                      />
                    </div>
                  </div>
                  <div className="xl:w-[65vh] lg:ml-28 xl:ml-0 text-start intermediate:w-[100%] pb-11 xl:text-end">
                    <h1 className="font-bold text-4xl xl:text-8xl md:text-6xl text-[#3d4dff]">
                      {heading}
                    </h1>
                    <p className="xl:mb-14 xl:mt-10 mb-5 mt-5 xl:text-lg intermediate:text-base text-sm phone:w-[80%] md:w-[90%] w-[95%] lg:text-xl xl:w-full font-semibold xl:font-medium">
                      {desc}
                    </p>
                    <div className="grid grid-cols-2 lg:grid-cols-2 w-full intermediate:w-[65%] xl:w-full xl:gap-14 gap-1">
                      {items.map(({ title, items: subItems }, id) => (
                        <div key={id}>
                          <h2 className="xl:font-bold font-semibold text-sm md:text-xl xl:text-2xl mb-5 w-full xl:w-[95%]">
                            {title}
                          </h2>
                          <div className="xl:ml-4">
                            {subItems.map((item, i) => (
                              <p
                                className="mb-4 xl:text-[16px] 2xl:text-lg text-sm md:text-base xl:font-medium flex xl:justify-end justify-start items-center gap-x-3"
                                key={i}
                              >
                                <span className="xl:w-[6px] xl:h-[6px] w-1 h-1 bg-black rounded-full md:ml-2 xl:hidden"></span>
                                <span className="xl:transition-transform xl:duration-300 xl:hover:-translate-x-4">
                                  {item}
                                </span>
                                <span className="xl:w-[6px] xl:h-[6px] w-1 h-1 bg-black rounded-full mr-3 hidden xl:block"></span>
                              </p>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className=" xl:w-[65vh] pb-11 lg:ml-20 xl:ml-0 intermediate:w-[100%]">
                    <h1 className="font-bold text-4xl md:text-6xl xl:text-8xl text-[#3d4dff]">
                      {heading}
                    </h1>
                    <p
                      className="xl:mb-14 xl:mt-10 mb-5 mt-5 xl:text-lg intermediate:text-base text-sm phone:w-[80%]
                   md:w-[90%] w-[95%]  xl:w-full font-semibold xl:font-medium "
                    >
                      {desc}
                    </p>
                    <div className="grid grid-cols-2 lg:grid-cols-2 w-full intermediate:w-[65%] xl:w-full  xl:gap-14 gap-1">
                      {items.map(({ title, items: subItems }, id) => (
                        <div key={id}>
                          <h2 className="xl:font-bold font-semibold text-sm md:text-xl xl:text-2xl mb-5 w-full xl:w-[95%]">
                            {title}
                          </h2>
                          <ul className="list-disc">
                            {subItems.map((item, i) => (
                              <p
                                className="mb-4 xl:text-[16px] 2xl:text-lg text-sm md:text-base xl:font-medium flex justify-start items-center gap-x-3"
                                key={i}
                              >
                                <span className="xl:w-[6px] xl:h-[6px] w-1 h-1 bg-black rounded-full md:ml-2 xl:hidden"></span>
                                <span className="xl:w-[6px] xl:h-[6px] w-1 h-1 bg-black rounded-full mr-3 hidden xl:block"></span>
                                <span className="xl:transition-transform xl:duration-300 xl:hover:translate-x-4">
                                  {item}
                                </span>
                              </p>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="relative">
                    <div
                      className="rounded-[40px] overflow-hidden"
                      style={{
                        clipPath: "url(#solutionClipPath)",
                      }}
                    >
                      <img
                        className="object-cover"
                        alt="Solution Gif"
                        src={gif}
                        loading="lazy"
                      />
                    </div>
                    <div className="absolute xl:left-1 left-0 bottom-1">
                      <OutlinedArrowButton
                        buttonText={"Featured Projects"}
                        textSize="text-sm"
                        onClick={() => {
                          navigate(RouteNames.PROJECTS);
                        }}
                        className={
                          "xl:py-[6px] xl:px-[16px] phone:px-8 phone:py-2 py-[4px] px-[6px] mt-12"
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )
        )}
      </div>
    </>
  );
};

export default SolutionWork;
