import React from "react"
import quotes from "../../assets/HomeIcons/Quotes.svg"
import rustom from "../../assets/Testimonial_pics/Rustom.jpeg"
import khyati from "../../assets/Testimonial_pics/Khyati.png"
import ankit from "../../assets/Testimonial_pics/Ankit.jpeg"
import zayaan from "../../assets/Testimonial_pics/Zayaan.jpeg"
import sneha from "../../assets/Testimonial_pics/sneha.jpeg"
import spriha from "../../assets/Testimonial_pics/spriha.jpeg"
import linkedin from "../../assets/HomeIcons/Linkedin.svg"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Testimonial = () => {
  const data = [
    {
      name: "Rustom Lawyer",
      title: "Co-Founder & CEO, Augnito",
      description:
        "Augnito’s collaboration with Kartik for the visual design and branding experience has been a total game changer. His innovative design thinking and passion breathed new life into our projects, contributing substantially to our brand's growth. Each creative left a significant impact and was developed with fresh ideas. Highly impressed with the work!",
      photo: rustom,
      LinkedIn: "https://www.linkedin.com/in/rustom-lawyer",
    },
    {
      name: "Sneha Biswas",
      title: "Founder & CEO, Early Steps Academy",
      description:
        "I highly recommend Slashon for their exceptional work in building Early Steps Academy. From concept to execution, they manages our social media with innovative ideas and quick delivery. Their talent and ease of collaboration significantly enhances our marketing strategy. I'm confident they will excel in future projects and deliver outstanding results.",
      photo: sneha,
      LinkedIn: "https://www.linkedin.com/in/sneha-biswas25/",
    },
    {
      name: "Zayyan Waseem",
      title: "Head- content, Impressario",
      description:
        "We had a short time working together but it was wonderful! Really admire Kartik’s dedication & passion towards design. I’ve seen at several instances on how he worked with the team, as well as ours to brainstorm ideas and know every detail to deliver more than expected! Wishing you the best, always!",
      photo: zayaan,
      LinkedIn: "https://www.linkedin.com/in/zayyan-waseem-036568a4/",
    },
    {
      name: "Ankit Medarwal",
      title: "Founder & MD, The Rising",
      description:
        "Trust their process…They’re experienced and passionate for their work. I’ve enjoyed working with Slashon on various marketing campaigns, but majorly redesigning our brand identity including logo design. Our projects were executed efficiently, keeping in consideration the budget and performance results!",
      photo: ankit,
      LinkedIn: "https://www.linkedin.com/in/ankit-medatwal-03353311/",
    },
    {
      name: "Khyati Thakur",
      title: "Sr. Software Engineer, Inspirit",
      description:
        "Had the pleasure of partnering with Slashon’s creative team for our multiple needs, relating to brand identity, social media marketing, and other digital marketing campaigns. Everytime they work with passion and utmost attention to details, they have always been able to unlock solutions that deliver results. Highly appreciate their team efforts! Our team loved working with them!",
      photo: khyati,
      LinkedIn: "https://www.linkedin.com/in/khyatithakur/",
    },
    {
      name: "Spriha Biswas",
      title: "Forbes 30 under 30",
      description:
        "Working with Slashon has been a game-changer for our brand! Their creativity, attention to detail, and ability to understand our vision made the entire process seamless. From our revamped logo to the social media strategy, every piece was spot on. We've seen a significant increase in engagement and customer interest since partnering with them. Slashon is hands down the best agency for startups looking to make an impact!",
      photo: spriha,
      LinkedIn: "https://www.linkedin.com/in/spriha-biswas/",
    },
  ]

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    swipeToSlide: true,
    centerPadding: "100px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const settingsSm = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    swipeToSlide: true,
    centerPadding: "10px",
  }

  return (
    <div className="pb-40 relative">
      <p className="text-lightBlack font-bold text-3xl xl:text-5xl text-center md:text-start px-3 md:px-16 py-12">
        Driving results & Building trust
        <br className="hidden md:block" />
        with leading brands
      </p>

      {/* Small Screen */}
      <div className="lg:hidden" id="home-testimonials-sm">
        <Slider {...settingsSm}>
          {data.map((e) => (
            <div className="border-[1px] border-lightBlack rounded-[36px] px-5 pb-5 pt-14 max-w-[587px] min-h-[570px] max-h-[570px] flex flex-col justify-between h-full">
              <div className="">
                <img
                  src={quotes}
                  alt=""
                  className="w-9 h-7 xl:w-12 xl:h-10 mb-3"
                  loading="lazy"
                />
                <p className="text-base text-lightBlack xl:max-w-[522px] xl:h-[222px] leading-6">
                  {e?.description}
                </p>
              </div>
              <div className="flex items-center gap-2 mt-12">
                <img
                  src={e?.photo}
                  alt=""
                  className="object-cover w-10 h-10 xl:w-16 xl:h-16 rounded-full"
                  loading="lazy"
                />
                <div className="flex flex-1 items-center justify-between">
                  <div>
                    <p className="xl:text-xl text-lightBlack font-bold">
                      {e?.name}
                    </p>
                    <p className="text-xs xl:text-base text-lightBlack">
                      {e?.title}
                    </p>
                  </div>

                  <img
                    src={linkedin}
                    alt=""
                    loading="lazy"
                    onClick={() => window.open(e?.LinkedIn, "_blank")}
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Large Screen */}
      <div className="hidden lg:block" id="home-testimonials">
        <Slider {...settings}>
          {data.map((e) => (
            <div className="border-[1px] border-lightBlack rounded-[36px] px-8 pb-8 pt-16">
              <div className="">
                <img
                  src={quotes}
                  alt=""
                  className="w-9 h-7 xl:w-12 xl:h-10 mb-3"
                  loading="lazy"
                />
                <p className="text-base text-lightBlack max-w-[522px] h-[222px] leading-6">
                  {e?.description}
                </p>
              </div>
              <div className="flex items-center gap-2 mt-16">
                <img
                  src={e?.photo}
                  alt=""
                  className="object-cover w-10 h-10 xl:w-16 xl:h-16 rounded-full"
                  loading="lazy"
                />
                <div className="flex flex-1 items-center justify-between">
                  <div>
                    <p className="xl:text-xl text-lightBlack font-bold">
                      {e?.name}
                    </p>
                    <p className="text-xs xl:text-base text-lightBlack">
                      {e?.title}
                    </p>
                  </div>

                  <img
                    src={linkedin}
                    alt=""
                    loading="lazy"
                    onClick={() => window.open(e?.LinkedIn, "_blank")}
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default Testimonial
