import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const useIsXlScreen = () => {
  const [isXlScreen, setIsXlScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsXlScreen(window.innerWidth >= 1240);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return isXlScreen;
};

const ProjectCard = ({
  video,
  title,
  titleType,
  description,
  categories,
  projectPath,
  alt,
  caption,
  desc,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showArrow, setShowArrow] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const isXlScreen = useIsXlScreen();
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let interval;
    if (isHovered) {
      interval = setInterval(() => {
        setShowArrow((prev) => !prev);
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [isHovered]);

  const handleViewProjectClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/projects/${projectPath}`);
  };

  return (
    <div
      className="xl:break-inside-avoid xl:mx-16 mb-24 relative md:max-w-[600px] xl:max-w-[550px]"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        setShowArrow(true);
      }}
    >
      <div className="relative">
        <svg width="0" height="0" style={{ position: "absolute" }}>
          <defs>
            <clipPath id="responsiveClipPath" clipPathUnits="objectBoundingBox">
              <path d="M0.51428,0.08126C0.50458,0.03563,0.47631,0.00477,0.44455,0.00477H0.08137C0.03818,0.00477,0.00319,0.05715,0.00319,0.12183V0.88276C0.00319,0.94744,0.03818,0.99982,0.08137,0.99982H0.92216C0.96535,0.99982,1.00034,0.94744,1.00034,0.88276V0.27487C1.00034,0.21019,0.96535,0.15781,0.92216,0.15781H0.58516C0.5534,0.15781,0.52513,0.12695,0.51543,0.08126H0.51428Z" />
            </clipPath>
          </defs>
        </svg>
        <div
          className={`relative ${
            isHovered ? "xl:clip-path-border" : ""
          } clip-path-border xl:clip-path-none`}
          style={{
            clipPath:
              !isXlScreen || isHovered ? "url(#responsiveClipPath)" : "none",
          }}
        >
          <video
            id="stories-video"
            src={video}
            muted
            autoPlay
            loop
            playsInline
            preload="metadata"
            className="w-full rounded-[40px]"
          />

          {(isHovered || !isXlScreen) && (
            <svg
              className="absolute inset-0 w-full h-full pointer-events-none"
              viewBox="0 0 1 1"
              preserveAspectRatio="none"
            >
              <path
                d="M0.51428,0.08126C0.50458,0.03563,0.47631,0.00477,0.44455,0.00477H0.08137C0.03818,0.00477,0.00319,0.05715,0.00319,0.12183V0.88276C0.00319,0.94744,0.03818,0.99982,0.08137,0.99982H0.92216C0.96535,0.99982,1.00034,0.94744,1.00034,0.88276V0.27487C1.00034,0.21019,0.96535,0.15781,0.92216,0.15781H0.58516C0.5534,0.15781,0.52513,0.12695,0.51543,0.08126H0.51428Z"
                fill="none"
                stroke="#242424"
                strokeWidth="0.002"
              />
            </svg>
          )}
        </div>
        {(isHovered || !isXlScreen) && (
          <>
            <div className="absolute top-2 right-1 md:right-6 md:top-3 xl:top-2 xl:right-3 flex gap-2">
              {categories.map((category, index) => (
                <motion.div
                  key={index}
                  initial={{ x: -50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  className="bg-gray-200 text-black px-3 py-1  md:px-6 md:py-2 xl:px-3 xl:py-1 rounded-full text-xs md:text-sm xl:text-base"
                >
                  {category}
                </motion.div>
              ))}
            </div>
            {(isXlScreen || isHovered) && (
              <div
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                onClick={handleViewProjectClick}
              >
                <div className="w-20 h-20 bg-themeBlue rounded-full flex items-center justify-center drop-shadow-2xl">
                  <div className="w-12 h-12 [perspective:1000px]">
                    <div
                      className={`relative w-full h-full transition-transform duration-500 [transform-style:preserve-3d] ${
                        showArrow ? "" : "[transform:rotateY(-180deg)]"
                      }`}
                    >
                      <div
                        className={`absolute inset-0 flex items-center justify-center [backface-visibility:hidden] ${
                          showArrow ? "visible" : "invisible"
                        }`}
                      >
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            id="Vector"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.64645 12.3536C3.45118 12.1583 3.45118 11.8417 3.64645 11.6464L11.2929 4H5C4.72386 4 4.5 3.77614 4.5 3.5C4.5 3.22386 4.72386 3 5 3H12.5C12.7761 3 13 3.22386 13 3.5V11C13 11.2761 12.7761 11.5 12.5 11.5C12.2239 11.5 12 11.2761 12 11V4.70711L4.35355 12.3536C4.15829 12.5488 3.84171 12.5488 3.64645 12.3536Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div
                        className={`absolute inset-0 flex items-center justify-center [backface-visibility:hidden] [transform:rotateY(180deg)] ${
                          showArrow ? "invisible" : "visible"
                        }`}
                      >
                        <span className="text-sm font-bold text-white text-center">
                          View Project
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className="text-themeBlue text-xl font-bold flex justify-start items-center py-4 gap-4 ">
        <h3>{title}</h3>
        <div className="h-1.5 w-1.5 rounded-full bg-lightBlack"></div>
        <p>{titleType}</p>
      </div>
      <div className="text-black text-xl xl:text-2xl">
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ProjectCard;
