import React from "react";

import img1 from "../../assets/Work page AI images/0_1 (1).jpg";
import img2 from "../../assets/Work page AI images/resize_ai.jpg";
import img3 from "../../assets/Work page AI images/adeline_as_An_enticing_top-down_shot_of_nachos_with_melted_chee_5510b911-2b24-4200-9486-f164058a3426.jpg";
import img4 from "../../assets/Work page AI images/adeline_as_An_overhead_shot_of_a_black_bowl_brimming_with_nacho_01c7358a-60bc-4244-9767-19a1555a91eb.jpg";
import img5 from "../../assets/Work page AI images/adeline_as_Produce_an_image_of_a_minimalist_display_featuring_a_31476d4a-92fc-402d-80e1-231240ed92c9.jpg";
import img6 from "../../assets/Work page AI images/OIG1.jpeg";
import img7 from "../../assets/Work page AI images/shivanizaveri_a_27_year_old_group_of_4_friends_male_and_female__beaa4875-579f-4788-a688-e987efbbb96a.jpg";
import img8 from "../../assets/Work page AI images/shivanizaveri_A_baby_in_the_arms_of_the_mother_wearing_virtual__c08d389b-0a4b-46df-86b0-688586a91e4b.jpg";
import img9 from "../../assets/Work page AI images/shivanizaveri_closeup_view_of_preparing_cocktails_bar_details_23ea2648-06ea-462b-8f92-ecc47e4d9bb4.jpg";
import img10 from "../../assets/Work page AI images/shivanizaveri_create_an_image_of_an_modern_contemporary_reali_72b7201d-0482-4561-9450-49f6f82f5878_0 1.jpg";
import img11 from "../../assets/Work page AI images/shivanizaveri_create_an_image_of_an_modern_contemporary_realist_ede5e8eb-6e9e-4ee8-b143-9743174545f5 (2) 1.jpg";
import img12 from "../../assets/Work page AI images/shivanizaveri_create_an_Instagram_grid_of_6_pictures_of_a_influ_dbf2f468-aeae-4202-8246-5156c53d4636 1.jpg";
import img13 from "../../assets/Work page AI images/shivanizaveri_Happy_Indian_Boys_Discovering_the_World_in_Virtua_dd6c59c9-edfb-4afd-9847-1f4ee10ee46d (1).jpg";
import img14 from "../../assets/Work page AI images/shivanizaveri_photography_of_Shawarma_with_feta_cheese_and_oliv_29a14b89-ffb9-46f9-a28c-861ee25d0063 1.jpg";
import img15 from "../../assets/Work page AI images/shivanizaveri_closeup_view_of_preparing_cocktails_bar_details_23ea2648-06ea-462b-8f92-ecc47e4d9bb4.jpg";
import img16 from "../../assets/Work page AI images/shivanizaveri_professional_photography_of_the_most_refined_and__4ea43bed-bbe0-4768-8ee0-9f2a041068c5.jpg";
import img17 from "../../assets/Work page AI images/shivanizaveri_Van_Gogh_painted_a_self_portrait_in_his_bedroom_3dbc42d3-e9d7-4a1c-85bd-3d6a7862c5c9_2 1.jpg";
import Marquee from "react-fast-marquee";

const ImageCarousel = () => {
  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
  ];

  const aiImages = [...images, ...images];

  return (
    <div id="image-carousel">
      <p className="text-lightBlack font-bold text-4xl xl:text-5xl mt-16 xl:mt-32 mb-8 text-center px-5 xl:mx-20">
        Some of our
        <br />
        AI generated Images
      </p>

      <div className="my-20 overflow-hidden">
        <Marquee loop={0} speed={120}>
          {aiImages.map((image, index) => (
            <div className="px-5 xl:px-10 max-w-[350px] w-auto ">
              <img
                className="max-h-[500px] w-auto rounded-[20px] object-contain"
                src={image}
                key={index}
                alt=""
                loading="lazy"
              />
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default ImageCarousel;
