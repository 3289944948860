import React from "react";
import { projectData } from "../../data/projectData";

const ImageGrid = ({ images, columns, noFullWidth }) => (
  <div
    className={`grid grid-cols-${columns} place-items-start items-center gap-2 sm:gap-4 rounded-xl`}
  >
    {images?.map((img, index) => (
      <div key={index} className={`${noFullWidth ? "" : "w-full"}`}>
        <img
          src={img}
          alt={`Project ${index + 1}`}
          className=" w-full object-cover rounded-xl"
          loading="lazy"
        />
      </div>
    ))}
  </div>
);

const ProjectSectionImage = ({ currentProject }) => {
  const project = projectData.find((p) => p.path === currentProject?.path);
  const images = project?.projectImages || [];

  return (
    <div className="grid gap-4 max-w-[1200px] mx-auto px-4 lg:px-8">
      {/* Project Mark Safety */}
      {currentProject?.path === "marksafety" && (
        <>
          <ImageGrid images={[images[0]]} columns={1} />
          <ImageGrid images={[images[1], images[2]]} columns={2} noFullWidth />
          <ImageGrid images={[images[3], images[4]]} columns={2} />
          <ImageGrid images={[images[5]]} columns={1} />
          <ImageGrid images={[images[6]]} columns={1} />

          <ImageGrid images={[images[7], images[8]]} columns={2} />
          <ImageGrid images={[images[9]]} columns={1} />

          <div className="">
            <ImageGrid images={[images[10]]} columns={1} />
          </div>
          {/* 
          <div className="rounded-3xl">
            <ImageGrid images={[images[12]]} columns={1} />
          </div> */}
        </>
      )}

      {/* Project Property Experts */}
      {currentProject?.path === "propertyexperts" && (
        <>
          <ImageGrid images={[images[0]]} columns={1} />
          <ImageGrid images={[images[1]]} columns={1} />
          <ImageGrid images={[images[2]]} columns={1} />
          <ImageGrid images={[images[3], images[4]]} columns={2} />
          <ImageGrid images={[images[5]]} columns={1} />
        </>
      )}

      {/* Project Mother Dairy */}
      {currentProject?.path === "motherdairy" && (
        <>
          <ImageGrid images={[images[0]]} columns={1} />
          <ImageGrid images={[images[1], images[2]]} columns={2} />
          <ImageGrid images={[images[3]]} columns={1} />
          <ImageGrid images={[images[4], images[5]]} columns={2} />
          <ImageGrid images={[images[6]]} columns={1} />
          <ImageGrid images={[images[7]]} columns={1} />
        </>
      )}

      {/* Project CashKaro */}
      {currentProject?.path === "cashkaro" && (
        <>
          <ImageGrid images={[images[0]]} columns={1} />
          <ImageGrid images={[images[1], images[2]]} columns={2} />
          <ImageGrid images={[images[3]]} columns={1} />
          <ImageGrid images={[images[9], images[10]]} columns={2} />

          <div className="grid grid-cols-2 gap-6">
            <ImageGrid images={[images[4], images[6]]} columns={1} />
            <ImageGrid images={[images[5], images[7], images[8]]} columns={1} />
          </div>
          <ImageGrid images={[images[11]]} columns={1} />

          <ImageGrid images={[images[12]]} columns={1} />
        </>
      )}
    </div>
  );
};

export default ProjectSectionImage;
