// Solution

import playfulGIF from "../assets/About Page gifs/Playful.gif";
import innovateGIF from "../assets/About Page gifs/innovate.gif";
import minimalismGIF from "../assets/About Page gifs/minimalism.gif";
import quirkySVG from "../assets/About Page gifs/Quirk the quirky.gif";

// About

//aboutDetail

export const text = [
  {
    content:
      "Hi, I’m Kartik, Founder and Creative Head at Slashon. I’m passionate about transforming brands through creative innovation and fresh, impactful ideas. With over 5 years in the industry, I’ve had the privilege of working with leading brands as an Art Director, post my graduation from MSU Baroda with a degree in Design and Advertising.",
  },
  {
    content:
      "Design has always been part of my journey—raised in a home where creativity thrived (thanks to my father, an artist for 30+ years), I grew up surrounded by art and innovation. Creativity has been in my DNA from the start.",
  },
  {
    content:
      "I bring humor, a touch of quirk, and relentless dedication to shaping the vision and growth of every brand we work with. It’s about ensuring that ideas aren’t just heard, but brought to life in unique and meaningful ways. Turning my freelance journey into a full-time agency has been a dream come true—but we’ve just started, and there are miles to go.",
  },
  {
    content: "Let’s grow together and create something incredible!",
  },
];

export const aboutgroup = [
  {
    title: (
      <>
        <span className="font-medium">
          We <strong>Innovate</strong> your identity
        </span>
      </>
    ),
    desc: "Why settle for the basics when you can be extraordinary? Reimagining your brand’s visual identity to make it more unique, relevant, and impactful to stand different from the rest, and cater results above expectations.",
    gif: innovateGIF,
    imgPosition: false,
    isImage: false,
  },
  {
    title: (
      <>
        <span className="font-medium">
          We get your <strong>Playful </strong>persona on
        </span>
      </>
    ),
    desc: "Our real flex is that we want a bit of playfulness in the ongoing trends and social media visuals. Our mission is to build brands with a universal perspective and a pinch of fun and positive aura.",
    gif: playfulGIF,
    imgPosition: true,
    isImage: false,
  },
  {
    title: (
      <>
        <span className="font-medium ml-1">
          We maximize <strong>Minimalism</strong>
        </span>
      </>
    ),
    desc: "Our intention isn’t stripping the excess; but focusing on what truly matters. In the world of noise and clutter, minimal concepts are purposeful. We let your brand breathe, speak, shine and bring its real self on the front foot decluttering the rest.",
    gif: minimalismGIF,
    imgPosition: false,
    isImage: false,
  },
  {
    title: (
      <>
        <span className="font-medium ml-1">
          We quirk the <strong>Quirky</strong>
        </span>
      </>
    ),
    desc: "Adding unexpected twists, souring the sarcasm, and uplifting the unique bits from a large portion is our expertise. Creating light-hearted moments, along with adding individuality and experiences for brands.",
    gif: quirkySVG,
    imgPosition: true,
    isImage: false,
  },
];
